import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layouts/index';
import HandingEndoscopeImg from '../../images/handing-endoscope.jpg'
import XrayImg from '../../images/xray.jpg'
import UltrasonicReflectoscopeControllerImg from '../../images/ultrasonic-reflectoscope-controller.jpg'

const EquipmentsContent = () => (
  <div className="container sub-content">
    <div className="box">
      <div id="endoscope">
        <h2>内視鏡検査について</h2>
        <div>
          <div className="row">
            <div className="col-sm-4">
              <img
                className="img-thumbnail"
                src={HandingEndoscopeImg}
                alt="内視鏡検査について"
              />
            </div>
            <div className="col-sm-8">
              <p>
                当院では、フジフイルム社の内視鏡機器（EG-L580NW）を用い、鼻から診る苦痛の少ない胃カメラ検査を実施。
                <br />
                従来のバリウム検査と比べ、はるかに精密な検査が可能であり、バリウム検査では発見の困難な早期胃がんも検出しております。
                <br />
                検査中もご自身の胃の中を見られ、検査後には胃カメラの写真もお渡しし、詳細な説明をお渡ししております。
              </p>
              <span>＞院長ブログより</span>
              <ul className="list-unstyled">
                <li>
                  <Link to="/blogs/2011-5-31">
                    2011.05.31　当院の内視鏡洗浄
                  </Link>
                </li>
                <li>
                  <Link to="/blogs/2010-7-1_2">
                    2010.06.30　お酒で顔が赤くなる人は食道癌に注意！
                  </Link>
                </li>
                <li>
                  <Link to="/blogs/2010-7-1">
                    2010.06.30　当院の胃カメラは食道もしっかり。
                  </Link>
                </li>
                <li>
                  <Link to="/blogs/2009-6-19">
                    2009.06.18　バリウムより胃カメラ、除菌
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="box">
      <div id="xray">
        <h2>レントゲンについて</h2>
        <div>
          <div className="row">
            <div className="col-sm-4">
              <img
                className="img-thumbnail"
                src={XrayImg}
                alt="レントゲンについて"
              />
            </div>
            <div className="col-sm-8">
              <p>
                あまり知られていないことですが、レントゲンにもかなり画質の差があります。
                <br />
                当院ではデジタル式の高画質なレントゲン装置を使用。
                <br />
                より正確な診断を目指しています。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="box">
      <div id="echo">
        <h2>超音波専門女性技師によるエコー検査</h2>
        <div>
          <div className="row">
            <div className="col-sm-4">
              <img
                className="img-thumbnail"
                src={UltrasonicReflectoscopeControllerImg}
                alt="エコー検査"
              />
            </div>
            <div className="col-sm-8">
              <p>
                当院では、超音波専門のベテラン女性技師によるエコー検査を提供しています。
                <br />
                エコー検査装置として、
                <strong>大学病院級の装置である、日立製 ARIETTA S70</strong>
                を使用し、より確実な診断を追求。
                <br />
                腹部エコー、乳腺エコーの他、頚動脈エコー、心エコー、甲状腺エコー等も施行可能です。
                <br />
                また、一般の健診センターなどでは数をこなすため、どうしても流れ作業的な短時間の検査しかできないのが実情ですが、当院では枠を減らすことでたっぷりと検査時間を用意。
                <br />
                大雑把な検査ではなく、見逃しの少ない、しっかりとした検査を受けることができます。
                <br />
                （例：一般的な健診センターでの腹部エコー検査は６～７分程度。当院では１０～２０分かけてしっかり観察します。）
              </p>
            </div>
          </div>
          <h3>超音波検査件数</h3>
          <table className="table table-bordered text-center">
            <thead>
              <tr>
                <th />
                <th className="text-center">
                  2018年度
                  <br />
                  （2018年4月～2019年3月）
                </th>
                <th className="text-center">
                  2017年度
                  <br />
                  （2017年4月～2018年3月）
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>腹部</td>
                <td>1,090件</td>
                <td>1,102件</td>
              </tr>
              <tr>
                <td>心臓</td>
                <td>42件</td>
                <td>45件</td>
              </tr>
              <tr>
                <td>頸動脈</td>
                <td>49件</td>
                <td>49件</td>
              </tr>
              <tr>
                <td>乳腺</td>
                <td>408件</td>
                <td>456件</td>
              </tr>
              <tr>
                <td>甲状腺</td>
                <td>88件</td>
                <td>103件</td>
              </tr>
              <tr>
                <td>
                  <b>合計</b>
                </td>
                <td>
                  <b>1,677件</b>
                </td>
                <td>
                  <b>1,755件</b>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <span>＞院長ブログより</span>
            <ul className="list-unstyled">
              <li>
                <Link to="/blogs/2019-9-16">
                  健康診断日誌　2019.09.16　当院の超音波検査機器
                </Link>
              </li>
              <li>
                <Link to="/blogs/2009-8-3_2">
                  健康診断日誌　2009.08.03　乳がんの自己検診
                </Link>
              </li>
              <li>
                <Link to="/blogs/2009-7-20">
                  健康診断日誌　2009.07.20　乳がん　エコー検査が人気
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Equipments = () => (
  <Layout>
    <EquipmentsContent />
  </Layout>
);

export default Equipments;
